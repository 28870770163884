import Helpers from '../lib/helpers';

class ToolFab {
  constructor({ selector, cssClass }) {
    this.selector = selector;
    this.cssClass = cssClass;
    this.fab = document.querySelector(selector);
    this.fabBtn = this.fab.querySelector('.btn');

    this.fab.classList.add('alert-widget--visible');

    this.fab.addEventListener('click', (e) => {
      if (e.target !== this.fabBtn) {
        this.fab.classList.toggle(this.cssClass);
      } else {
        return;
      }
    });

    const fabDebouncer = Helpers.debounce(() => {
      this.addClass();
    }, 30);

    window.addEventListener('scroll', fabDebouncer);
  }

  addClass() {
    if (this.fab.classList.contains(this.cssClass)) return;
    this.fab.classList.add(this.cssClass);
  }
}

export default ToolFab;
