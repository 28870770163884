import Cookies from 'js-cookie/dist/js.cookie.min';

class UtmCookie {
  constructor(win) {
    this.win = win;
    this.input = document.querySelector('[data-utm-string]');

    if (win.location.search !== '') {
      this.searchString = win.location.search;
      this.saveUtmVars();
    }

    this.attemptFill();
  }

  attemptFill() {
    if (!this.input) return;

    this.input.value = Cookies.get('ljcms_utmvars');
  }

  saveUtmVars() {
    Cookies.set('ljcms_utmvars', this.utmVars().join(','));
  }

  searchVars() {
    return this.searchString.replace(/^\?/, '').split('&');
  }

  utmVars() {
    return this.searchVars().filter((e) => e.includes('utm_'));
  }
}

export default UtmCookie;
